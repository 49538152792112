import React, { useEffect, useState } from 'react'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as S from './styled'
import BrandCard from './BrandCard'
import PinkLine from '../shared/PinkLine'
import { getCities, getUnitsApi } from '../../services/laravelService'
import { statesBr } from '../../utils/states'
import { useForm } from 'react-hook-form'
import RangeInput from '../shared/RangeInput'
import useIsBrazil from '../../utils/isBrazil'
import ModalDasa from '../shared/Modal'

const ExamLocations = props => {
  const { watch, register, setValue } = useForm()

  const stateId = watch('states')
  const cityName = watch('cities')

  const isBrazil = useIsBrazil()
  const [showModal, setShowModal] = useState(false)

  const [error, setError] = useState({
    units: false,
    cities: false,
  })
  const handleApiError = (routeName, error) => {
    setError(prevState => ({
      ...prevState,
      [routeName]: error,
    }))
  }

  const [units, setUnits] = useState([])
  const [cities, setCities] = useState([])
  const [nearbyUnits, setNearbyUnits] = useState([])
  const [cep, setCep] = useState('')
  const [range, setRange] = useState('20')

  const [location, setLocation] = useState({ latitude: '', longitude: '' })
  const brandsData = props.examsLocation
  const data = props.data

  const reqLocationSuccess = position => {
    setLocation({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    })
  }

  const reqLocationError = () => {}

  async function getUnits(paramUf, paramCity, paramCep) {
    const result = await getUnitsApi(paramUf, paramCity, paramCep, error, handleApiError)
    console.log(result.data)
    setUnits(result.data)
  }

  const reqLocation = () => {
    navigator.geolocation.getCurrentPosition(
      position => {
        navigator.geolocation.getCurrentPosition(reqLocationSuccess, reqLocationError)
        getUnits('', '', '')
      },
      negative => {
        setShowModal(true)

        setTimeout(() => {
          setShowModal(false)
        }, 3000)
      }
    )
  }

  const handleOnChangeCep = e => {
    setCep(e.target.value)
  }

  const calculeDistance = coordsUnit => {
    // Função para converter graus em radianos
    const toRad = x => (x * Math.PI) / 180

    // Coordenadas do usuário
    const latitudeUser = parseFloat(location.latitude)
    const longitudeUser = parseFloat(location.longitude)

    // Coordenadas da unidade
    const latitudeUnit = parseFloat(coordsUnit.latitude)
    const longitudeUnit = parseFloat(coordsUnit.longitude)

    // Radio da terra em quilômetros
    const earthRadiusKm = 6371

    // Diferença de latitude e longitude em radianos
    const deltaLatitude = toRad(latitudeUnit - latitudeUser)
    const deltaLongitude = toRad(longitudeUnit - longitudeUser)

    // Cálculo intermediário da fórmula de Haversine
    const haversineFormula =
      Math.sin(deltaLatitude / 2) * Math.sin(deltaLatitude / 2) +
      Math.cos(toRad(latitudeUser)) *
        Math.cos(toRad(latitudeUnit)) *
        Math.sin(deltaLongitude / 2) *
        Math.sin(deltaLongitude / 2)

    // Distância angular em radianos
    const angularDistance =
      2 * Math.atan2(Math.sqrt(haversineFormula), Math.sqrt(1 - haversineFormula))

    // Distance in kilometers
    const distanceKm = earthRadiusKm * angularDistance

    return distanceKm
  }

  const getClosestUnits = () => {
    return units
      .map(unit => ({
        ...unit,
        distance: calculeDistance({
          latitude: unit.latitude,
          longitude: unit.longitude,
        }),
      }))
      .sort((a, b) => a.distance - b.distance)
      .filter(unitsFiltered => unitsFiltered.distance < range)
  }

  useEffect(() => {
    /* solicita permissão para acessar localização do usuario */
    if (isBrazil) {
      reqLocation()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBrazil])

  useEffect(() => {
    if (location.latitude !== '' && location.longitude !== '') {
      const nearbyUnits = getClosestUnits()
      setNearbyUnits(nearbyUnits)
    } else if (stateId !== undefined && stateId !== null && stateId) {
      setNearbyUnits(units)
    } else if (cep && !stateId) {
      setNearbyUnits(units)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, units])

  useEffect(() => {
    if (stateId !== undefined && stateId !== null && stateId) {
      async function getCitiesByStates() {
        const citiesByStates = await getCities(stateId, error, handleApiError)

        setCities(citiesByStates.data)
      }
      const stateSelected = statesBr.find(state => String(state.id) === String(stateId))
      if (stateSelected) {
        getUnits(stateSelected.uf.toLowerCase(), '', '')
        setCep('')
        setLocation({
          latitude: '',
          longitude: '',
        })
        setValue('cities', '')
      }
      getCitiesByStates()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateId])

  useEffect(() => {
    if (cityName) {
      const stateSelected = statesBr.find(state => String(state.id) === String(stateId))
      if (stateSelected) {
        getUnits(stateSelected.uf.toLowerCase(), cityName ?? '', cep)
        setCep('')
      } else {
        getUnits('', cityName, cep)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityName])

  useEffect(() => {
    const nearbyUnitsForRange = getClosestUnits()
    setNearbyUnits(nearbyUnitsForRange)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range])

  useEffect(() => {
    if (cep.length === 8) {
      const stateSelected = statesBr.find(state => String(state.id) === String(stateId))
      if (stateSelected) {
        getUnits(stateSelected.uf.toLowerCase(), cityName ?? '', cep)
      } else {
        getUnits('', cityName ?? '', cep)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cep])

  return (
    <>
      {isBrazil ? (
        <S.ExamLocationsWrapper>
          <S.ContentContainer>
            <S.TitlePage>Encontre nossas unidades</S.TitlePage>
            <S.ComboBoxContainer>
              <S.ContentWrapper>
                <S.TitleSelect>CEP</S.TitleSelect>
                <S.InputCustom type="text" onChange={handleOnChangeCep} value={cep} maxLength={8} />
              </S.ContentWrapper>
              <S.ContentWrapper>
                <S.TitleSelect>Estados</S.TitleSelect>
                <S.SelectItens name="states" ref={register}>
                  <S.OptionItens value={''} disabled selected hidden>
                    Selecione um estado
                  </S.OptionItens>
                  {statesBr.map(state => {
                    return (
                      <S.OptionItens key={state.id} value={state.id}>
                        {state.name}
                      </S.OptionItens>
                    )
                  })}
                </S.SelectItens>
              </S.ContentWrapper>
              <S.ContentWrapper>
                <S.TitleSelect>Cidades</S.TitleSelect>
                <S.SelectItens name="cities" ref={register}>
                  <S.OptionItens value={''} disabled selected hidden>
                    Selecione uma cidade
                  </S.OptionItens>
                  {cities.map(city => {
                    return (
                      <S.OptionItens key={city.id} value={city.name}>
                        {city.name}
                      </S.OptionItens>
                    )
                  })}
                </S.SelectItens>
              </S.ContentWrapper>
            </S.ComboBoxContainer>
            {location.latitude !== '' && location.longitude !== '' && (
              <S.ContentRangeInput>
                <S.TitleRangeInput>
                  Filtro de distância da sua localização até uma unidade
                </S.TitleRangeInput>
                <RangeInput valueRange={range} setvalueRange={setRange} />
              </S.ContentRangeInput>
            )}
          </S.ContentContainer>
          <S.UnitsContainer>
            {nearbyUnits.map(unit => {
              return (
                <S.CardsContainerUnits key={unit.id}>
                  <S.TitleCard>{unit.name}</S.TitleCard>
                  <S.SubtitleCard>
                    {`${unit.address_street}, ${unit.address_number}, ${
                      unit.address_complement ?? ''
                    }, `}
                    {`${unit.address_neighborhood},${unit.address_city}-${unit.address_state}, `}
                    {`CEP: ${unit.cep}`}
                  </S.SubtitleCard>
                  <S.DetailContainer>
                    <S.SubtitleCard>
                      <S.LinkCardExternal
                        href={`https://www.google.com/maps/dir//${unit.address_street},+${unit.address_number}+-+${unit.address_neighborhood},+${unit.address_city}+-+${unit.address_state}/@${unit.latitude},${unit.longitude}`}
                      >
                        <FontAwesomeIcon color="#02103F" icon={faMapMarkerAlt} /> Como Chegar
                      </S.LinkCardExternal>
                    </S.SubtitleCard>
                    <S.SubtitleCard>
                      {/* <S.LinkCard to="/">Ver detalhes</S.LinkCard> */}
                    </S.SubtitleCard>
                  </S.DetailContainer>
                </S.CardsContainerUnits>
              )
            })}
          </S.UnitsContainer>
        </S.ExamLocationsWrapper>
      ) : (
        <S.ExamLocationsWrapper>
          <S.ExamLocationsTitle>{data.title}</S.ExamLocationsTitle>
          <S.ExamLocationsDescription>{data.demonstrative_text}</S.ExamLocationsDescription>
          <S.CardsContainer>
            {brandsData.map(brand => (
              <BrandCard key={brand.id} acf={brand.acf} brand={brand.title} />
            ))}
          </S.CardsContainer>
        </S.ExamLocationsWrapper>
      )}

      <PinkLine />

      <ModalDasa setShowModal={setShowModal} showModal={showModal}>
        <S.ContainerText>
          <S.TextInfoModal>
            Para visualizar os laboratórios próximos a você, <br /> ative a localização do seu
            navegador.
          </S.TextInfoModal>
        </S.ContainerText>
      </ModalDasa>
    </>
  )
}

export default ExamLocations
